import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/app'
import 'firebase/firestore'
import '@/styles/main.css'

const firebaseConfig = {
  apiKey: "AIzaSyBwqQb0WbDYqGANMLn5gY2lHSWoMTRXAKs",
  authDomain: "autodiagnostico-mecanismo.firebaseapp.com",
  projectId: "autodiagnostico-mecanismo",
  storageBucket: "autodiagnostico-mecanismo.appspot.com",
  messagingSenderId: "69530776455",
  appId: "1:69530776455:web:24d7d437c31acc69299922"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
