import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentComponente: 'SeleCuestionario',
    currentPerfil: '',
  },
  mutations: {
    currentChange(state,data) {
      data.forEach(dato => {
        state[dato.toChange] = dato.value
      });
    },
  },
  actions: {
    initialState({commit}) {
      commit('currentChange', [
        {toChange: 'currentPerfil', value: null},
        {toChange: 'currentComponente', value: 'SeleCuestionario'}
      ])
      localStorage.clear()
    }
  }
})
