<template>
  <v-app>
    <v-main>
      <router-view :key="$route.fullPath"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
/* html, body {
  height: 100%;
} */
html {
  scroll-behavior: smooth;
}

/* .logo a {
  color:  white !important;
  text-decoration: none;
} */
a {
  color: #7C4DFF;
}
</style>
