import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Inicio.vue')
  },
  {
    path: '/diagnostico',
    name: 'Diagnostico',
    component: () => import(/* webpackChunkName: "Diagnostico" */ '../views/Diagnostico.vue')
  },
  {
    path: '/reporte',
    name: 'Reporte',
    component: () => import(/* webpackChunkName: "Reporte" */ '../views/Reporte.vue')
  },
  {
    path: '/:category/:module',
    name: 'Test',
    component: () => import(/* webpackChunkName: "Diagnostico" */ '../views/Diagnostico.vue')
    // component: () => import(/* webpackChunkName: "DiagnosticoTest" */ '../views/DiagnosticoTest.vue')
  },
  {
    path: '/:category/:module/impacto',
    name: 'Impacto',
    component: () => import(/* webpackChunkName: "Impacto" */ '../views/Impacto.vue')
  },
  {
    path: '/:category/:module/:agresion',
    name: 'ModuloPreguntasAgresion',
    component: () => import(/* webpackChunkName: "Diagnostico" */ '../views/Diagnostico.vue')
    // component: () => import(/* webpackChunkName: "ModuloPreguntasAgresion" */ '../views/DiagnosticoTest.vue')
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
